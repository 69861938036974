import { QueryClient, useQueryClient } from '@tanstack/vue-query'
import { FetchError } from 'ofetch'

import type { ComponentInternalInstance } from 'vue'
import type { FormKitNode } from '@formkit/core'
import type { ToastOptions } from 'vue3-toastify'
import type { MaybeRef } from '~/types/helpers'

export interface UseSubmitHandlerContext<D = unknown> {
  queryClient: QueryClient
  node?: FormKitNode
  instance: ComponentInternalInstance
  data: D
}

export type UseSubmitHandlerRequest<D = unknown, R = unknown> = (
  data: D,
  context: UseSubmitHandlerContext<D>,
) => Promise<R>

export interface UseSubmitHandlerOptions<D = unknown, R = unknown> {
  beforeSubmit?: (
    context: UseSubmitHandlerContext<D>,
  ) => Promise<false | void> | false | void
  onSuccess?: (
    response: R,
    context: UseSubmitHandlerContext<D>,
  ) => Promise<void> | void
  onError?: (
    error: unknown,
    context: UseSubmitHandlerContext<D>,
  ) => Promise<void> | void
  onFinally?: (context: UseSubmitHandlerContext<D>) => Promise<void> | void
  onDisplayFormkitError?: (
    data: any,
    context: UseSubmitHandlerContext<D>,
  ) => Promise<void> | void
  loadingMessage?: MaybeRef<string | false>
  successMessage?: MaybeRef<string | false>
  errorMessage?: MaybeRef<string | false>
  errorFormkitOptions?: {
    localSources?: string[]
    mapper?: Record<string, string>
  }
  toastOptions?: ToastOptions
  shouldEmit?: boolean
  shouldThrow?: boolean
}

export type UseSubmitHandlerReturn<D = unknown, R = unknown> = (
  data?: D,
  node?: FormKitNode,
) => Promise<R | void>

export function useSubmitHandler<D = unknown, R = unknown>(
  request: UseSubmitHandlerRequest<D, R>,
  options: UseSubmitHandlerOptions<D, R> = {},
): UseSubmitHandlerReturn<D, R> {
  // COMPONENT INSTANCE
  const instance = getCurrentInstance()

  if (!instance) {
    throw new Error('This must be called within a setup function.')
  }

  // QUERY CLIENT
  const queryClient = useQueryClient()

  const {
    beforeSubmit = () => {
      //
    },
    onSuccess = () => {
      //
    },
    onError = () => {
      //
    },
    onFinally = () => {
      //
    },
    onDisplayFormkitError = (data, { node }) => {
      const { localErrors, childErrors } = requestToFormkitErrors(
        data,
        errorFormkitOptions.localSources ?? [],
        errorFormkitOptions.mapper ?? {},
      )
      node?.setErrors(localErrors, childErrors)
    },
    loadingMessage = 'Sending...',
    successMessage = 'Submitted successfully',
    errorMessage = 'Something went wrong',
    errorFormkitOptions = {},
    toastOptions = {},
    shouldEmit = false,
    shouldThrow = false,
  } = options

  // TOAST
  const toast = useToast(toastOptions)

  return async (data?: D, node?: FormKitNode): Promise<R | void> => {
    const context: UseSubmitHandlerContext<D> = {
      queryClient,
      node,
      instance,
      data: data as D,
    }

    try {
      const _beforeSubmit = await beforeSubmit(context)

      if (_beforeSubmit === false) {
        return
      }

      // Display loading toast
      const _loadingMessage = toValue(loadingMessage)
      if (_loadingMessage) toast.loading(_loadingMessage)

      // Make request
      const response = await request(data as D, context)

      // Call onSuccess callback
      await onSuccess(response, context)

      // Dispatch success toast
      const _successMessage = toValue(successMessage)
      if (_successMessage) toast.success(_successMessage)

      // Emit submit event
      if (shouldEmit) {
        instance?.emit('submit', response)
      }

      // Return response, so FormKit can emit `submit:success` event.
      return response
    } catch (e) {
      // Log error
      console.error(e)

      // If request was aborted, remove toast and does not display error
      if (isAbortError(e as any)) {
        toast.remove()
        return
      }

      // Call onError callback
      await onError(e, context)

      const _errorMessage = toValue(errorMessage)

      // Dispatch error toast
      if (_errorMessage) toast.error(_errorMessage)

      // Normalize errors to FormKit and display them in the form
      if (e instanceof FetchError && e.data) {
        await onDisplayFormkitError(e.data, context)
      } else if (_errorMessage) {
        node?.setErrors([_errorMessage])
      }

      // Throw error again.
      if (shouldThrow) {
        throw e
      }
    } finally {
      await onFinally(context)
    }
  }
}
