import { toast, type ToastOptions, Id } from 'vue3-toastify'

export type IToastType = 'loading' | 'success' | 'error'

export const useToast = (globalOptions: ToastOptions = {}) => {
  let toastId: Id | undefined

  const createOrUpdate = (options: {
    type: IToastType
    message: string
    defaultOptions?: ToastOptions
    customOptions?: ToastOptions
  }) => {
    const { type, message, defaultOptions = {}, customOptions = {} } = options

    if (toastId) {
      toast.update(toastId, {
        // Default options
        ...defaultOptions,
        // Custom options
        ...globalOptions,
        ...customOptions,
        // Options that can't be overridden
        render: message,
        type: type,
        isLoading: type === 'loading',
      })
    } else {
      toastId = toast[type](message, {
        // Default options
        ...defaultOptions,
        // Custom options
        ...globalOptions,
        ...customOptions,
      })
    }
  }

  return {
    loading: (message: string, options: ToastOptions = {}) => {
      createOrUpdate({
        type: 'loading',
        message,
        customOptions: options,
      })
    },
    success: (message: string, options: ToastOptions = {}) => {
      createOrUpdate({
        type: 'success',
        message,
        defaultOptions: {
          autoClose: 2000,
          closeOnClick: true,
          closeButton: false,
        },
        customOptions: options,
      })
    },
    error: (message: string, options: ToastOptions = {}) => {
      createOrUpdate({
        type: 'error',
        message,
        defaultOptions: {
          autoClose: 5000,
          closeOnClick: true,
          closeButton: true,
        },
        customOptions: options,
      })
    },
    remove: () => toast.remove(toastId),
  }
}
